import { ClientSafeConfig } from '@huffpost/interface/dist/client';
import AU from './au/au.yml';
import BR from './br/br.yml';
import CA from './ca/ca.yml';
import ES from './es/es.yml';
import FR from './fr/fr.yml';
import GR from './gr/gr.yml';
import IN from './in/in.yml';
import IT from './it/it.yml';
import JP from './jp/jp.yml';
import KR from './kr/kr.yml';
import QC from './qc/qc.yml';
import SpoilsUK from './spoilsuk/spoilsuk.yml';
import UK from './uk/uk.yml';
import US from './us/us.yml';

export const primeOrder = [
  US,
  CA,
  UK,
  AU,
  BR,
  ES,
  FR,
  GR,
  IN,
  IT,
  JP,
  KR,
  QC,
  SpoilsUK,
].map((edition) => ({
  ...(ClientSafeConfig.isEditionId(edition.id) ? ClientSafeConfig.byEdition[edition.id] : {}),
  ...edition,
}));

export function carmotProviderEnabled(id) {
  return ['us'].indexOf(id) > -1;
}

