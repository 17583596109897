import storage from '~/public/assets/javascripts/storage';
import cohorts from '~/public/assets/javascripts/cohorts';
import '~/public/assets/javascripts/cohort-tracking';

if (window.HUFFPOST) {
  window.HUFFPOST.modules = {
    storage,
    cohorts,
  };
}
