const storage = (function isLocalStorageSupported() {
  try {
    const testKey = 'test';
    window.localStorage.setItem(testKey, '1');
    window.localStorage.removeItem(testKey);
    return window.localStorage;
  } catch (e) {
    return null;
  }
}());

const enabled = !!storage;

function deserialize(value) {
  if (typeof value !== 'string') {
    return undefined;
  }
  try {
    return JSON.parse(value);
  } catch (e) {
    return value || undefined;
  }
}

function serialize(value) {
  return JSON.stringify(value);
}

function noop() {}

let get = noop;
let set = noop;
let remove = noop;
let clear = noop;
let has = function () { return false; };
if (enabled) {
  get = function (key) {
    return deserialize(storage.getItem(key));
  };
  set = function (key, item) {
    try {
      storage.setItem(key, serialize(item));
    } catch (e) {
      // eslint-bite-me
    }
  };
  remove = function (key) {
    storage.removeItem(key);
  };
  clear = function () {
    storage.clear();
  };
  has = function (key) {
    return storage.getItem(key) !== undefined;
  };
}

export default {
  enabled,
  get,
  set,
  remove,
  clear,
  has,
};
