const isCustomEventConstructor = (() => {
  try {
    // eslint-disable-next-line
    new CustomEvent('test');
    return true;
  } catch (e) {
    return false;
  }
})();

const buildCustomEvent = (name, payload) => {
  // For IE11 support.
  // @see https://caniuse.com/#search=CustomEvent
  if (!isCustomEventConstructor) {
    const event = document.createEvent('CustomEvent');
    event.initCustomEvent(name, false, false, payload);
    return event;
  }

  return new CustomEvent(name, { detail: payload });
};

export default (name, payload = {}, elem = document) => {
  const event = buildCustomEvent(name, payload);
  return elem.dispatchEvent(event);
};
