import abeagle from './abeagle';

const testSlug = 'hp_outbrain_test';

if (window.HP.params.isEntry || window.HP.params.isFront) {
  const hasExcludeTag = window.HP.params.isEntry && (window.HP.entry.modulousTags.includes('@nooutbrain') || window.HP.entry.modulousTags.includes('@notaboola'));

  if (hasExcludeTag) {
    window.HP.params.abOutbrainTest.doNotPublishEvent = true;
  }

  if (window.HP.params.abeagle && Array.isArray(window.HP.params.abeagle) && window.HP.params.abeagle.includes(testSlug)) {
    document.addEventListener('abeagleEvent', () => {
      const variant = abeagle.getExperimentVariant(testSlug);
      const outbrainModules = document.querySelectorAll('.outbrain-module');
      const hasOutbrainModules = !!outbrainModules.length;
      if (hasOutbrainModules && variant !== 'outbrain_placement_hidden' && !hasExcludeTag) {
        outbrainModules.forEach((n) => n.classList.add('show'));
      }
    }, { once: true });
    window.HP.params.abOutbrainTest.ready = true;
  }
}
