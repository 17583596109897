if (document.querySelector('body.entry') && document.querySelector('.rail--left')) {
  document.addEventListener('scroll', () => {
    const leftRail = document.querySelector('.rail--left');
    const shareBar = leftRail.querySelector('.share-bar--side');
    const topAd = document.querySelector('.advertisement.ad-leaderboard-flex.treated');
    // const siteHeader = document.querySelector('.js-site-header-fixed');
    const entryHead = document.querySelector('.entry-head-container');
    const isVideo = document.body.classList.contains('video');
    const mainContentList = isVideo ? document.querySelector('.js-video-entry-content') : document.querySelector('.js-main-content-list');

    if (leftRail && shareBar) {
      const topAdHeight = topAd ? topAd.offsetHeight : 0;
      // const topHeight = 0.5 * (topAdHeight + siteHeader.offsetHeight + entryHead.offsetHeight);
      const topHeight = isVideo ? 0 : 0.5 * (topAdHeight + entryHead.offsetHeight);
      const shareRect = shareBar.getBoundingClientRect();
      const mclRect = mainContentList.getBoundingClientRect();
      // toggle hidden at top of entry content and bottom
      if (window.scrollY >= topHeight && shareRect.bottom < mclRect.bottom) {
        shareBar.classList.remove('hidden');
      } else {
        shareBar.classList.add('hidden');
      }
    }
  });
}
