const checkConsentParams = () => {
  const urlParams = window.location.search;
  const displayConsent = urlParams.includes('display-consent') && ['us', 'uk'].includes(window.HP.params.edition);
  if (displayConsent) {
    // eslint-disable-next-line no-console
    console.log('forcing display consent');
    const otIntervalId = setInterval(() => {
      if (window.OneTrust && document.getElementsByClassName('onetrust-pc-dark-filter').length > 0) {
        // eslint-disable-next-line new-cap
        window.OneTrust.ToggleInfoDisplay();
        clearInterval(otIntervalId);
      }
    }, 500);
  }
};

checkConsentParams();
