import cookies from '@buzzfeed/bf-utils/lib/cookies';

// do nothing if liveramp cookie already exists or there is no hem cookie:
if (!document.cookie.match(/_lr_env=/) && document.cookie.match(/hem=/)) {
  const hemValue = atob(decodeURIComponent(cookies.get('hem').replace(/"/g, '')));
  window.addEventListener('envelopeModuleReady', () => {
    // eslint-disable-next-line no-undef
    atsenvelopemodule.setAdditionalData({
      type: 'emailHashes',
      id: [hemValue], // should be value of "hem" cookie
    });
  });
}
