/* global HP */

const stickyNav = document.querySelector('.js-site-header-fixed');
const stickyContainer = document.querySelector('.right-rail-sticky');
const rightRail = stickyContainer && stickyContainer.closest('.rail');
const additionalOffset = document.body.classList.contains('entry') ? 30 : 0;
const mainContentList = document.querySelector('.js-main-content-list');
const twilightZone = document.querySelector('.js-zone-twilight');
const enableRightRailSticky = HP && HP.params && HP.params.features && HP.params.features.rightRailSticky && HP.params.edition !== 'us';

// handles right rail sticky behavior
// can probably put this in request animation frame as well
if (stickyContainer && rightRail && enableRightRailSticky) {
  document.addEventListener('scroll', () => {
    // turn off sticky right rail at either:
    // - bottom of main content list
    // order above is important!

    const rightRailRect = rightRail.getBoundingClientRect();
    let maxY;
    let bottomOffset;

    if (mainContentList) {
      const mclRect = mainContentList.getBoundingClientRect();
      maxY = mclRect.bottom;
      bottomOffset = rightRailRect.height - mclRect.height;
    } else if (twilightZone) {
      const tzRect = twilightZone.getBoundingClientRect();
      maxY = tzRect.bottom;
      bottomOffset = 0;
    }

    let stickyNavHeight = 78; // TODO this is news but be smarter
    if (stickyNav) {
      const stickyNavRect = stickyNav.getBoundingClientRect();
      stickyNavHeight = stickyNavRect.top + stickyNavRect.height;
    }

    const stickyTop = rightRailRect.top <= stickyNavHeight + additionalOffset;
    const stickyBottom = maxY <= stickyContainer.offsetHeight + stickyNavHeight + additionalOffset;
    stickyContainer.classList.toggle('sticky', stickyTop && !stickyBottom);
    stickyContainer.classList.toggle('sticky-bottom', stickyBottom);
    stickyContainer.style.top = stickyBottom ? '' : `${stickyNavHeight + additionalOffset}px`;
    stickyContainer.style.width = `${rightRail.offsetWidth}px`;
    stickyContainer.style.bottom = stickyBottom && bottomOffset ? `${bottomOffset}px` : '';
  });

  document.addEventListener('see-more', () => {
    if (window.adsReloadAd) {
      const theAd = stickyContainer.querySelector('.ad-right_rail_flex') || stickyContainer.querySelector('.ad-right_rail_300x250_lower');
      if (theAd) {
        window.adsReloadAd(theAd);
      }
    }
  });
}
