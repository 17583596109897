import mapiRequest from '~/public/assets/javascripts/mapi';

let profilePromise;

export default function getProfile() {
  if (!profilePromise) {
    profilePromise = mapiRequest('user/profile')
      .then(({ profile }) => profile)
      .catch(() => null);
  }
  return profilePromise;
}
