/* eslint camelcase: 0 */

import { primeOrder as editions } from '~/config/editions/ClientSafeEditions';

export function mapAmpDataVars(node) {
  if (!node) return null;
  const attrs = Array.from(node.attributes).filter((attr) => attr.name.match(/^data-vars-/) && attr.value);
  if (attrs.length === 0) return null;
  const entries = attrs.map((attr) => {
    const name = attr.name.match(/^data-vars-(.*)/)[1].replace(/-/g, '_');
    return [name, attr.value];
  });
  return Object.fromEntries(entries);
}

export function findVidibleCarouselDataVars(node) {
  if (!node) return null;
  const tile = node.closest('.o2-cvs-carousel-tile');
  if (!tile) return null;
  const index = [].slice.call(tile.parentElement.children).indexOf(tile);
  // Beware: extremely brittle Vidible-specific logic. Not sure if this can be committed or not.
  let videoId;
  const thumbnail = tile.querySelector('.o2-cvs-video-thumbnail');
  if (thumbnail && thumbnail.hasAttribute('style')) {
    // just letting it be known, that a data-video-id attribute would be handy
    const backgroundImage = thumbnail.getAttribute('style').match(/url\(['"](.+?)['"]\)/);
    if (backgroundImage) {
      // go home, eslint, you're drunk.
      /* eslint-disable-next-line prefer-destructuring */
      videoId = backgroundImage[1].split('/').slice(-2, -1)[0];
    }
  }
  return {
    item_name: videoId,
    item_type: 'card',
    position_in_subunit: index,
    target_content_id: videoId,
    target_content_type: 'video',
    type: 'web_internal_link',
  };
}

export function findVidiblePlayButtonDataVars(node) {
  if (!node) return null;
  const playButton = node.closest('.play-button');
  if (!playButton) return null;
  // If state is playing, action is to pause, and vice versa.
  const isPlaying = !!playButton.querySelector('.playing-state');
  const name = isPlaying ? 'pause' : 'play';
  const action = isPlaying ? 'press_pause' : 'press_play';
  return {
    item_name: name,
    item_type: 'button',
    position_in_subunit: null, // What does this mean for button clicks?
    action_type: 'navigate',
    action_value: action,
    type: 'web_content_action',
  };
}

function inSidebar(node) {
  // Assume a 10-year-old browser is running on a desktop
  let atDesktopWidth = true;
  if (typeof window.matchMedia === 'function') {
    // FIXME: keep this in sync with the actual breakpoint. So frustrating!
    atDesktopWidth = window.matchMedia('(min-width: 1024px)').matches;
  }
  return atDesktopWidth && !!node.closest('.js-right-container');
}

function isBelowDesktop() {
  let belowDesktop = false;
  if (typeof window.matchMedia === 'function') {
    // FIXME: keep this in sync with the actual breakpoint. So frustrating!
    belowDesktop = !window.matchMedia('(min-width: 1024px)').matches;
  }
  return belowDesktop;
}

function generateSelector(mainSelector, selectorArray) {
  let finalSelector = '';
  selectorArray.forEach((selector, index, array) => {
    finalSelector += `${mainSelector} ${selector}`;
    if (index !== array.length - 1) {
      finalSelector += ', ';
    }
  });
  return finalSelector;
}
export function positionInUnit(node, pageType, unitType, unitName, selector = []) {
  // As this is on a responsive page, we must figure out if the component is
  // being rendered in the right column (front-page only due to its structure), or in the main column (it never moves
  // positions in the markup).
  // For front if item is in sidebar and we are on desktop treat it as right unit, if its below desktop then there is no sidebar treat all as main unit, else it is desktop and not on sidebar so select all but exclude the ones on sidebar
  // For entries we have buzz_head, buzz_body, buzz_bottom on the main column and we have right sidebar and left sidebar (sharebar)
  let position = 0;
  let unitSelector = '';
  const itemSelectors = selector.length > 0 ? selector : ['.js-card[data-vars-type]', 'a[data-vars-type]:not(.js-card[data-vars-type] a)', 'button[data-vars-type]', '.js-cet-item', '.js-cet-subbuzz'];
  const subunit = node.closest('.js-cet-subunit');
  if (!subunit) return null;

  if (pageType === 'buzz') {
    if (unitType === 'sidebar') {
      unitSelector = unitName === 'left' ? '.js-cet-unit-left' : '.js-cet-unit-right';
    } else if (unitName === 'site_header' || unitType === 'nav_bar') {
      unitSelector = '.js-cet-unit-site-header';
    } else if (unitName === 'top_ad') {
      unitSelector = '.js-cet-unit-top-ad';
    } else {
      unitSelector = `.js-cet-unit-${unitType}`;
    }
  } else if (inSidebar(subunit) || unitType === 'sidebar') {
    unitSelector = '.js-cet-unit-right';
  } else if (isBelowDesktop()) {
    unitSelector = '.js-cet-unit-main';
  } else if (unitName === 'site_header' || unitType === 'nav_bar') {
    unitSelector = '.js-cet-unit-site-header';
  } else if (unitName === 'top_ad') {
    unitSelector = '.js-cet-unit-top-ad';
  } else {
    const allUnitSelector = generateSelector('.js-cet-unit-main', itemSelectors);
    const rightUnitSelector = generateSelector('.js-cet-unit-right', itemSelectors);
    const allUnits = Array.from(document.querySelectorAll(allUnitSelector));
    const rightUnit = new Set(Array.from(document.querySelectorAll(rightUnitSelector)));
    const mainUnit = allUnits.filter((x) => !rightUnit.has(x));
    position = mainUnit.indexOf(node);
    if (position >= 0) {
      return position;
    }
  }

  const allItems = Array.from(document.querySelectorAll(generateSelector(unitSelector, itemSelectors)));
  position = allItems.indexOf(node);
  if (position >= 0) {
    return position;
  }
  return null;
}

export function unitDataVars(node, payload = {}) {
  const unit_name = payload && payload.unit_name ? payload.unit_name : 'main';
  const unit_type = payload && payload.unit_type ? payload.unit_type : 'feed';
  if (inSidebar(node)) {
    return { unit_name: 'right', unit_type: 'sidebar' };
  }
  return { unit_name, unit_type };
}

export function targetDataVars(headline) {
  const isNative = headline && headline.type === 'native';
  const isLinkout = headline && headline.type === 'linkout';
  const isInternalLinkout = isLinkout && headline.url && (
    headline.url.startsWith('/') || editions.some((e) => headline.url.startsWith(e.link))
  );
  const isInternal = isNative || isInternalLinkout;
  const type = isInternal ? 'web_internal_link' : 'web_external_link';

  let contentType = null;
  if (isInternal) {
    contentType = headline.url.includes('/entry/') ? 'buzz' : 'feed';
  }

  let contentId;
  try {
    if (isInternal) {
      contentId = headline.url.includes('/entry/')
        ? headline.entry_id
        : new URL(headline.url).pathname;
    } else {
      contentId = headline.url;
    }
  } catch (_) {
    // eslint
  }

  return { contentType, contentId, type };
}

export function splashItemtargetDataVars(url = {}) {
  const { value } = url;
  const isNative = url?.type === 'native';
  const isLinkout = url?.type === 'linkout';
  const isInternalLinkout = isLinkout && value && (
    value.startsWith('/') || editions.some((e) => value.startsWith(e.link))
  );
  const isInternal = isNative || isInternalLinkout;
  const type = isInternal ? 'web_internal_link' : 'web_external_link';

  let contentType = 'url';
  if (isInternal) {
    contentType = value.includes('/entry/') ? 'buzz' : 'feed';
  }

  let contentId;
  try {
    if (isInternal) {
      contentId = value.includes('/entry/')
        ? url.entry_id
        : new URL(value).pathname;
    } else {
      contentId = value;
    }
  } catch (_) {
    // eslint
  }

  return { contentType, contentId, type };
}
