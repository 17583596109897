const HEADER_TAGS = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'];

function canInjectBetween(prev, elem) {
  if (!prev) {
    return false;
  }
  if (prev.lastChild && HEADER_TAGS.includes(prev.lastChild.nodeName)) {
    return false;
  }
  return ![prev, elem].some((e) => e.classList.contains('js-no-inject'));
}

function getContentListItems(contentList) {
  return Array.prototype.slice.call(contentList.children).reduce((list, item) => {
    if (item.classList.contains('cli-listicle')) {
      return list.concat(Array.prototype.slice.call(item.children));
    }
    list.push(item);
    return list;
  }, []);
}

function moveBPageNewsletter() {
  const newsletters = document.querySelectorAll('.entry #newsletter-bottom');
  const contentList = document.querySelector('.js-entry-content');
  const placeholderOverride = document.querySelector('#js-newsletter-placeholder');

  if (contentList && newsletters.length) {
    const newsletter = newsletters[0];
    let insertionPoint;
    if (placeholderOverride) {
      insertionPoint = placeholderOverride;
    } else {
      let prevElem;
      insertionPoint = getContentListItems(contentList).find((elem, n) => {
        prevElem = contentList.children[n - 1];
        return (elem.offsetTop >= 1000) && canInjectBetween(prevElem, elem);
      });
    }
    if (insertionPoint) {
      insertionPoint.parentElement.insertBefore(newsletter, insertionPoint);
    }
  }
}

function moveFeatureNewsletter() {
  const newsletters = document.querySelectorAll('.feature .newsletter');
  const contentList = document.querySelector('.js-entry-content');
  const placeholderOverride = document.querySelector('#js-newsletter-placeholder');

  if (contentList && newsletters.length === 1) {
    const newsletter = newsletters[0];
    const insertionPoint = placeholderOverride;
    if (insertionPoint) {
      insertionPoint.parentElement.insertBefore(newsletter, insertionPoint);
    }
  }
}

function moveSectionNewsletter() {
  const newsletters = document.querySelectorAll('.a-page__content #newsletter-bottom .newsletter');
  const twilightZone = document.querySelector('#zone-twilight .zone__content');

  if (twilightZone && newsletters.length === 1) {
    const newsletter = newsletters[0];
    const insertionPoint = twilightZone.children[4] || null;
    twilightZone.insertBefore(newsletter, insertionPoint);
  }
}

window.addEventListener('hydration-complete', () => {
  moveBPageNewsletter();
  moveSectionNewsletter();
  moveFeatureNewsletter();
});
