import abeagle from './abeagle';

const testSlug = 'hp_sticky_video';

if (
  window.HP.params.edition === 'us'
  && window.HP.params.device === 'mobile'
  // && (window.HP.entry.sectionSlug === 'politics' || window.HP.entry.sectionSlug === 'entertainment')
  && window.HP.params.isEntry
  && !window.HP.entry.hasLiveblog
  && window.HP.params.abeagle
  && Array.isArray(window.HP.params.abeagle)
  && window.HP.params.abeagle.includes(testSlug)
) {
  document.addEventListener('abeagleEvent', () => {
    const variant = abeagle.getExperimentVariant(testSlug);
    window.HP.params.abStickyVideoTest.variant = variant;
  }, { once: true });
  window.HP.params.abStickyVideoTest.ready = true;
}
