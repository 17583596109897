/* eslint-disable no-console */
import React from 'react';
import ReactDom from 'react-dom';
import { RawIntlProvider, createIntl } from 'react-intl';
import HydratableComponents from './HydratableComponents';

const maybeParseJSON = (string) => {
  try {
    return JSON.parse(string);
  } catch (e) {
    return null;
  }
};

function loadComponentsToHydrate(domList) {
  // A quirk of async chunk loading to keep in mind:
  // When chunks are awaited one by one in a forEach loop, the component hydrates
  // incorrectly; possibly because the component hasn't finished rendering before
  // it gets appended to the dom? Unsure. However, mapping all components to
  // hydrate with the promise for their react component's async chunk in this
  // function, and then awaiting Promise.all on the return value, avoids that
  // issue entirely.
  const toHydrate = Array.prototype.map.call(domList, (reactWrapper) => ({
    reactWrapper,
    componentProps: maybeParseJSON(reactWrapper.getAttribute('data-component-props')),
    componentName: reactWrapper.getAttribute('data-component-name'),
    componentId: reactWrapper.getAttribute('data-component-id'),
  }));

  const componentNames = toHydrate.map((c) => c.componentName).reduce((acc, c) => {
    if (acc.indexOf(c) === -1) {
      acc.push(c);
    }
    return acc;
  }, []);
  console.info(`Hydrating ${toHydrate.length} components: ${componentNames.join(', ')}`);

  return toHydrate.map((config) => HydratableComponents[config.componentName]().then((mod) => Object.assign(config, { Component: mod.default })).catch((error) => {
    console.error(error);
    return config;
  }));
}

function intlMessages() {
  const intlRaw = document.getElementById('intl-messages');
  const intl = JSON.parse(intlRaw.text);
  return createIntl(intl);
}

document.addEventListener('DOMContentLoaded', async () => {
  const intl = intlMessages();
  const hydratePromises = loadComponentsToHydrate(document.querySelectorAll('.js-react-hydrator'));
  const toHydrate = await Promise.all(hydratePromises);

  const promises = toHydrate.map(async ({
    reactWrapper, componentProps, componentName, componentId, Component,
  }) => {
    if (!componentId) {
      console.error('Unable to hydrate react component, no id found.');
      return null;
    }

    if (Component && componentProps) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        try {
          const wrappedComponent = (
            <RawIntlProvider value={intl}>
              <Component {...componentProps} />
            </RawIntlProvider>
          );

          ReactDom.hydrate(wrappedComponent, reactWrapper, () => {
            // TODO: not sure how expensive this call is?
            const componentNode = reactWrapper.firstChild;
            reactWrapper.parentElement.replaceChild(componentNode, reactWrapper);
            resolve();
          });
        } catch (e) {
          console.error(`Error while hydrating "${componentName}" with id (${componentId})`, e);
          resolve();
        }
      });
    }
    console.error(`Unable to hydrate "${componentName}" with id (${componentId})`, componentProps);
    return null;
  });

  Promise.all(promises).then(() => window.dispatchEvent(new Event('hydration-complete')));
});
