/* eslint-disable no-console, template-curly-spacing */
import getProfile from '~/public/assets/javascripts/profile';
import mapiRequest from '~/public/assets/javascripts/mapi';
import storage from './storage';

const {
  isContributeThankYouPage, isMemberPage,
} = window.HP.params;
const isUS = window.HP.params.edition === 'us';
const validRoles = ['contributor_monthly', 'contributor_annually', 'contributor_once', 'contributor_canceled'];

const isValidEmail = (email) => email && typeof email === 'string' && email.includes('@');

const getCookieValue = (name) => (
  document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || ''
);

export const fetchFPUserData = async (profileData) => {
  try {
    const clientSessionId = getCookieValue('bf-xdomain-session-uuid');
    const response = await fetch('/api/flip-pay/user', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: profileData.email,
        name: profileData.name?.given,
        lastName: profileData.name?.family,
        register: true,
        browser_session_id: clientSessionId,
      }),
    });
    const respJson = await response.json();
    return respJson;
  } catch (error) {
    console.error('Error trying to get flip pay user access token and data', error);
    return null;
  }
};

const setNonRecurringContributorStorage = (role, roleCreatedAt) => {
  const MONTHS_TO_EXPIRE = 12;
  const roleCreatedAtDate = new Date(roleCreatedAt);
  storage.set('hp-contributor', {
    expires: roleCreatedAtDate.setMonth(roleCreatedAtDate.getMonth() + MONTHS_TO_EXPIRE),
    role,
    roleCreatedAt,
  });
};

const hasXMonthsPassedSinceDate = (date, x) => {
  const startDate = new Date(date);
  const dateXMonthsAgo = new Date();
  dateXMonthsAgo.setMonth(dateXMonthsAgo.getMonth() - x);
  return dateXMonthsAgo >= startDate;
};

const hasContributorStorageExpired = (expiresAt) => {
  const expirationDate = new Date(expiresAt);
  return expirationDate < Date.now();
};

const handlefpMemberCenterAction = async (event) => {
  const bodyToPatch = {};
  if (event.detail.type === 'cancel_renewals') {
    bodyToPatch.contributor_status = 'canceled';
    setNonRecurringContributorStorage('contributor_canceled', new Date());
  } else {
    bodyToPatch.contributor_status = event.detail.badge;
  }
  try {
    await mapiRequest('user/profile/edit', { body: { ...bodyToPatch } }, { method: 'PATCH' });
  } catch (error) {
    console.error('Error updating subscriber data:', error);
  }
};

const fpUserTypeToMapiRole = (fpUserData) => {
  const { type, canceled } = fpUserData;
  let role = null;
  // means user did a monthly or annually contribution but then canceled, we give the contributor canceled role
  if ((type === 'monthly' || type === 'annually') && canceled) {
    role = 'contributor_canceled';
  } else if (type) {
    role = `contributor_${type}`;
  }
  return role;
};

const handleNonRecurringContributorStatus = (currentRole, roleCreatedAt, monthsToHide) => {
  let contributorData = storage.get('hp-contributor');
  if (!contributorData || contributorData.role !== currentRole) {
    setNonRecurringContributorStorage(currentRole, roleCreatedAt);
    contributorData = storage.get('hp-contributor');
  }
  // hide the modules if less than x months have passed since the role was created
  // else show the new messaging
  // is-contributor class is used to hide the contribute modules in front and entries
  if (!hasXMonthsPassedSinceDate(contributorData.roleCreatedAt, monthsToHide)) {
    document.body.classList.add('is-contributor');
  } else {
    document.body.classList.add(currentRole);
  }

  if (hasContributorStorageExpired(contributorData.expires)) {
    document.body.classList.add('non-contributor');
  }
};

// Syncs mapi roles with roles returned by flip pay,
// returns the role that was set in mapi
export const syncMapi = async (fpUserData, profile) => {
  const { roles } = profile;
  if (fpUserData && fpUserData.data) {
    const { type, canceled, createdAt } = fpUserData.data;
    const fpRole = fpUserTypeToMapiRole(fpUserData.data);
    const bodyToPatch = {};
    let shouldUpdate = false;
    // if fpRole means user has contributed, check if present in profile roles if not add it.
    if (fpRole && !roles.includes(fpRole)) {
      shouldUpdate = true;
      // if user did a monthly or annually contribution but then canceled, we give the contributor canceled role
      bodyToPatch.contributor_status = (type === 'monthly' || type === 'annually') && canceled ? 'canceled' : type;
    } else if (!fpRole) {
      // if fp data didn't return type means user doesn't have contribution, check if it has one and clear it
      const hasRole = roles.some((r) => validRoles.includes(r));
      if (hasRole) {
        shouldUpdate = true;
        bodyToPatch.contributor_status = null;
      }
    }
    // let's crate the local storage for non recurring contributors
    if (fpRole === 'contributor_once' || fpRole === 'contributor_canceled') {
      const contributorData = storage.get('hp-contributor');
      const fpCreatedDate = new Date(createdAt);
      if (!contributorData || (contributorData && new Date(contributorData.roleCreatedAt) < fpCreatedDate)) {
        document.body.classList.add('is-contributor');
        setNonRecurringContributorStorage(fpRole, createdAt);
      }
    }
    if (shouldUpdate) {
      try {
        await mapiRequest('user/profile/edit', { body: { ...bodyToPatch } }, { method: 'PATCH' });
        return fpRole;
      } catch (error) {
        console.error('Error updating subscriber data:', error);
        return roles.length > 1 ? roles[1] : null;
      }
    }
    return roles.length > 1 ? roles[1] : null;
  }
  console.error('Error updating subscriber data, could not get fpUserData');
  return roles.length > 1 ? roles[1] : null;
};

if (isUS) {
  if (isMemberPage) {
    document.body.addEventListener('fpMemberCenterAction', handlefpMemberCenterAction);
  }
  const urlParams = new URLSearchParams(window.location.search);
  const authDone = urlParams.get('hp_auth_done');

  getProfile()
    .then(async (profile) => {
      if (profile && isValidEmail(profile.email)) {
        let currentRole = profile.roles.length > 1 ? profile.roles[1] : null;
        let roleCreatedAtDate = new Date();
        if (authDone === '1' || isContributeThankYouPage || isMemberPage) {
          try {
            const fpUserData = await fetchFPUserData(profile);
            const { createdAt } = fpUserData.data;
            if (!isMemberPage) {
              currentRole = await syncMapi(fpUserData, profile);
            }
            roleCreatedAtDate = createdAt;
          } catch (error) {
            console.error('Error trying to get flip pay user access token', error);
          }
        }
        const isContributor = validRoles.includes(currentRole);
        if (isContributor) {
          const buttonSupportHuffpost = document.querySelector('.front-support-huffpost__support-button, .cli-support-huffpost__support-button');

          // if logged in we can hide contributor modules based on the mapi role
          switch (currentRole) {
            case 'contributor_monthly':
            case 'contributor_annually':
              // if we have hp-contributor in local storage we should remove it, recurring contributions don't have this
              if (storage.has('hp-contributor')) {
                storage.remove('hp-contributor');
              }
              document.body.classList.add('is-contributor');
              document.body.classList.add(currentRole);
              break;
            case 'contributor_once':
              // When a logged in reader does a one-time contribution, let’s hide the modules for 2 month then show them a tailored message for a year
              handleNonRecurringContributorStatus(currentRole, roleCreatedAtDate, 2);
              if (buttonSupportHuffpost) {
                const itemNameOnce = buttonSupportHuffpost.getAttribute('data-vars-item-name');
                buttonSupportHuffpost.setAttribute('data-vars-item-name', `${itemNameOnce} (Once)`);
                buttonSupportHuffpost.removeAttribute('data-vars-item-name-overwritable');
              }
              break;
            case 'contributor_canceled':
              // When a logged in reader cancels their recurring contribution, let’s hide the modules for 1 month then show them a tailored message for a year
              handleNonRecurringContributorStatus(currentRole, roleCreatedAtDate, 1);
              if (buttonSupportHuffpost) {
                const itemNameOnce = buttonSupportHuffpost.getAttribute('data-vars-item-name');
                buttonSupportHuffpost.setAttribute('data-vars-item-name', `${itemNameOnce} (Canceled)`);
                buttonSupportHuffpost.removeAttribute('data-vars-item-name-overwritable');
              }
              break;
            default:
              document.body.classList.add('non-contributor');
          }
        } else {
          document.body.classList.add('non-contributor');
        }
      }
    });
}
