import dispatchEvent from './dispatch-event';

function dispatchOpenEvent(event) {
  dispatchEvent('see-more-button', null, event.target);
}

function catchOpenEvent(event) {
  event.currentTarget.classList.add('open');
}

Array.prototype.forEach.call(document.querySelectorAll('.js-openable'), (el) => {
  const content = el.querySelector('.js-openable-content');
  if (content.clientHeight > el.clientHeight) {
    const button = el.querySelector('.js-open');
    button.addEventListener('click', dispatchOpenEvent, { capture: true, once: true });
    el.addEventListener('see-more-button', catchOpenEvent, { capture: true, once: true });
  } else {
    el.classList.add('open');
  }
});
