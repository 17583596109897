/* eslint-disable camelcase */
const isEligible = window.HP.params?.features?.clientRecirc?.enabled;
const thisEntryId = window.HP.entry.id;

const fetchRecirc = async () => {
  const response = await fetch(`${origin}/api/recirc/entry${window.HP.params.features.clientRecirc.queryParams}`);

  if (response.ok) {
    const body = await response.json();
    // This processing to dedupe current article from recirc done here rather than in entryReircController so that we can avoid varying on recirc api endpoint per entry id:
    const bodyKeys = Object.keys(body);
    bodyKeys.forEach((key) => {
      const originalLength = body[key].length;
      body[key] = body[key].filter((card) => card?.headlines[0]?.entry_id !== thisEntryId);
      // remove extra card since no dedupe happened:
      if (body[key].length === originalLength) {
        body[key].pop();
      }
    });
    window.HP.recirc = {
      successful_fetch: true,
      ...body,
    };
  } else {
    window.HP.recirc = {
      successful_fetch: false,
    };
  }
};

if (isEligible) {
  fetchRecirc();
}
